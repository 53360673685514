import RecentTweets from "../../../../src/components/partials/recent-tweets";
import LatestProjects from "../../../../src/components/projects/latest-projects";
import LatestHobbies from "../../../../src/components/hobbies/latest-hobbies";
import LatestBlogs from "../../../../src/components/blogs/latest-blogs";
import RecentGitHubUserEvent from "../../../../src/components/partials/recent-github-user-events";
import * as React from 'react';
export default {
  RecentTweets,
  LatestProjects,
  LatestHobbies,
  LatestBlogs,
  RecentGitHubUserEvent,
  React
};